<template>
  <div class="my-4">
    <div
      class="flex mx--2 flex-row my-4px"
      v-for="index in customFieldStrings.map((_, i) => i).filter((i) => i % 2 === 0)"
      :key="index"
    >
      <div class="w-1/2">
        <BarcodeRow
          :labelWidth="40"
          :label="customFieldStrings[index]?.label"
          :hidden="customFieldStrings[index]?.hidden"
          :value="customFieldStrings[index]?.value"
        />
      </div>
      <div class="w-1/2" v-if="customFieldStrings[index + 1]">
        <BarcodeRow
          :labelWidth="40"
          :label="customFieldStrings[index + 1]?.label"
          :hidden="customFieldStrings[index + 1]?.hidden"
          :value="customFieldStrings[index + 1]?.value"
        />
      </div>
    </div>
    <div class="flex mx--2 flex-row my-4px" v-if="customFieldEsigns.length > 0">
      <div class="w-1/2">
        <BarcodeRowEsign :labelWidth="40" :customFieldImages="customFieldEsigns" />
      </div>
    </div>
    <div
      class="flex mx--2 flex-row my-4px"
      v-for="index in customFieldImages.map((_, i) => i).filter((i) => i % 2 === 0)"
      :key="index"
    >
      <div class="w-1/2">
        <BarcodeRowImage
          :labelWidth="40"
          :label="customFieldImages[index]?.label"
          :hidden="customFieldImages[index]?.hidden"
          :values="customFieldImages[index]?.files"
        />
      </div>
      <div class="w-1/2" v-if="customFieldImages?.[index + 1]">
        <BarcodeRowImage
          :labelWidth="40"
          :label="customFieldImages[index + 1]?.label"
          :hidden="customFieldImages[index + 1]?.hidden"
          :values="customFieldImages[index + 1]?.files"
        />
      </div>
    </div>
    <div class="flex mx--2 flex-row my-4px" v-for="index in customFieldFiles.map((_, i) => i)" :key="index">
      <div class="w-full">
        <BarcodeRowMulti
          :labelWidth="40"
          :label="customFieldFiles[index]?.label"
          :hidden="customFieldFiles[index]?.hidden"
          :values="customFieldFiles[index]?.files"
        />
      </div>
    </div>

    <div class="flex mx--2 flex-row my-4px" v-for="index in customFieldReferences.map((_, i) => i)" :key="index">
      <div
        class="w-full"
        v-if="customFieldReferences?.[index]?.files?.[0]?.url || customFieldReferences?.[index]?.text"
      >
        <div v-if="!customFieldReferences?.[index]?.text" class="w-full">
          <BarcodeRowMulti
            :labelWidth="40"
            :label="customFieldReferences[index]?.label"
            :hidden="customFieldReferences[index]?.hidden"
            :values="customFieldReferences[index]?.files"
          />
        </div>
        <div class="flex mx--2 flex-row my-4px" v-else>
          <div class="w-1/2">
            <BarcodeRow
              :labelWidth="40"
              :label="customFieldReferences[index]?.label"
              :hidden="customFieldReferences[index]?.hidden"
              :value="customFieldReferences[index]?.text"
            />
          </div>
          <div class="w-1/2" v-if="customFieldReferences[index + 1]">
            <BarcodeRow
              :labelWidth="40"
              :label="customFieldReferences[index + 1]?.label"
              :hidden="customFieldReferences[index]?.hidden"
              :value="customFieldReferences[index + 1]?.text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeRow from '@/components/barcode/BarcodeRow.vue'
import BarcodeRowEsign from '@/components/barcode/BarcodeRowEsign.vue'
import BarcodeRowImage from '@/components/barcode/BarcodeRowImage.vue'
import BarcodeRowMulti from '@/components/barcode/BarcodeRowMulti.vue'
import { CUSTOM_FIELD_KEY } from '@/utils/constants'
import { EFileKind } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeCustomField,
  ICustomFieldFileView,
  IFileItem,
  IProjectCustomField,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export interface IField {
  label: string
  value: string
  hidden: boolean
}

@Options({
  components: { BarcodeRow, BarcodeRowImage, BarcodeRowMulti, BarcodeRowEsign },
  name: 'BarcodeCustomField',
})
export default class BarcodeCustomField extends Vue {
  @Prop({ type: Object }) readonly barcodeCustomFields!: Record<string, IBarcodeCustomField>
  @Prop({ type: Object }) readonly projectCustomFields!: Record<string, IProjectCustomField>

  get customFieldStrings() {
    const customFields = [] as IField[]

    for (const key in this.barcodeCustomFields) {
      if (this.projectCustomFields?.[key]) {
        let value = ''
        switch (this.barcodeCustomFields[key].fieldType) {
          case CUSTOM_FIELD_KEY.text:
            value = this.barcodeCustomFields[key]?.text || ''
            break
          case CUSTOM_FIELD_KEY.number:
            value = (this.barcodeCustomFields[key]?.number || '').toString()
            break
          case CUSTOM_FIELD_KEY.singleSelect:
            value = this.barcodeCustomFields[key]?.singleSelect?.value || ''
            break
          case CUSTOM_FIELD_KEY.multiSelect:
            value = (this.barcodeCustomFields[key]?.multiSelect || []).map((item) => item.value).join(', ')
            break
          case CUSTOM_FIELD_KEY.date:
            value = this.barcodeCustomFields[key]?.date || ''
            break
          case CUSTOM_FIELD_KEY.phoneNumber:
            value = this.barcodeCustomFields[key]?.phoneNumber?.number || ''
            break
          case CUSTOM_FIELD_KEY.email:
            value = this.barcodeCustomFields[key]?.email || ''
            break
        }
        if (value) {
          customFields.push({
            label: this.projectCustomFields?.[key].label,
            hidden: !!this.projectCustomFields?.[key].isHidden,
            value,
          })
        }
      }
    }
    return customFields
  }

  get customFieldEsigns() {
    const customFields = [] as Array<Record<string, string>>
    if (this.barcodeCustomFields) {
      const eSigns = Object.entries(this.barcodeCustomFields).filter(
        (item) => item[1].fieldType === CUSTOM_FIELD_KEY.eSign
      )
      eSigns.forEach((item) => {
        if (item[1].eSign) {
          customFields.push({
            label: this.projectCustomFields[item[0]].label,
            value: item[1].eSign || '',
          })
        }
      })
    }
    return customFields
  }

  get customFieldImages() {
    const customFields = [] as Array<ICustomFieldFileView & { hidden: boolean }>
    if (this.barcodeCustomFields) {
      const files = Object.entries(this.barcodeCustomFields).filter(
        (item) => item[1].fieldType === CUSTOM_FIELD_KEY.files
      )
      files.forEach((item) => {
        if (item[1]?.files && item[1]?.files.length > 0) {
          const file = this.projectCustomFields?.[item[0]]
          if (file.label && file.fileKind === EFileKind.IMAGE) {
            customFields.push({
              label: this.projectCustomFields?.[item[0]].label,
              files: item[1]?.files || [],
              hidden: !!this.projectCustomFields?.[item[0]].isHidden,
            })
          }
        }
      })
    }

    return customFields
  }

  get customFieldFiles() {
    const customFields = [] as Array<ICustomFieldFileView & { hidden: boolean }>
    if (this.barcodeCustomFields) {
      const files = Object.entries(this.barcodeCustomFields).filter(
        (item) => item[1].fieldType === CUSTOM_FIELD_KEY.files
      )
      files.forEach((item) => {
        if (item[1]?.files && item[1]?.files.length > 0) {
          const file = this.projectCustomFields?.[item[0]]
          if (file.label && file.fileKind !== EFileKind.IMAGE) {
            customFields.push({
              label: this.projectCustomFields?.[item[0]].label,
              files: item[1]?.files || [],
              hidden: !!this.projectCustomFields?.[item[0]].isHidden,
            })
          }
        }
      })
    }

    return customFields
  }

  get customFieldReferences() {
    const customFields = [] as Array<ICustomFieldFileView & { hidden: boolean }>
    if (this.barcodeCustomFields) {
      const references = Object.entries(this.barcodeCustomFields).filter(
        (item) => item[1].fieldType === CUSTOM_FIELD_KEY.reference
      )
      references.forEach((item) => {
        if (item[1].reference) {
          const file = item?.[1]?.reference?.file || {
            path: '',
            url: '',
            name: '',
          }
          customFields.push({
            label: this.projectCustomFields[item[0]].label,
            text: item[1]?.reference.text,
            files: [file as IFileItem],
            hidden: !!this.projectCustomFields?.[item[0]].isHidden,
          })
        }
      })
    }
    return customFields
  }
}
</script>
