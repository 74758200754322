<template>
  <div class="flex mx--2">
    <div class="px-2px" :class="`w-${labelWidth}`">
      <div class="h-10 text-gray-500 bg-gray-100 px-2">
        <template v-if="isLabelTooltip">
          <div class="tooltip-label flex items-center h-full">
            <el-tooltip class="box-item w-full" effect="dark" :content="label" placement="top">
              <label>{{ displayLabel }}</label>
            </el-tooltip>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center h-full">
            <span class="mr-2">{{ label }}</span>
            <template v-if="hidden">
              <el-tooltip class="font-bold" effect="dark" :content="$t('hidden_field_tooltip')" placement="top">
                <span class="bold text-blue-600">ⓘ</span>
              </el-tooltip>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div :class="`w-full--${labelWidth} px-2px relative`">
      <div
        :class="
          `flex h-10 bg-white text-black items-center px-2 truncate leading-normal ${
            isCoppy && value ? 'pr-8' : 'pr-2'
          }`
        "
      >
        <template v-if="isTooltip">
          <div class="tooltip-button flex items-center h-full">
            <el-tooltip class="box-item" effect="dark" :content="value" placement="top">
              <label>{{ displayContent }}</label>
            </el-tooltip>
          </div>
        </template>
        <template v-else>
          {{ displayContent }}
        </template>
        <span class="cursor-pointer ml-4" v-if="isAddressLocation && value" @click="displayLocation(String(value))">
          <IconAddressLocation />
        </span>
      </div>
      <div
        class="icon-copy-container absolute right-px cursor-pointer"
        v-if="isCoppy && value"
        @click="copyClipboard(String(value))"
      >
        <IconCopy />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import IconAddressLocation from '@/components/svg/IconAddressLocation.vue'
import IconCopy from '@/components/svg/IconCopy.vue'
import { copyClipboard } from '@/utils/helpers'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ components: { IconCopy, IconAddressLocation }, name: 'BarcodeRow' })
export default class BarcodeRow extends Mixins(BarcodeRowMixin) {
  @Prop({ type: String }) readonly value?: string | number
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean
  @Prop({ type: Boolean, default: false }) readonly isCoppy!: boolean
  @Prop({ type: Boolean, default: false }) readonly isAddressLocation!: boolean

  get realLengthText() {
    return this.maxLengthFollowRealValue(this.value ?? '', this.maxLength ?? 40)
  }

  get isTooltip() {
    return this.value && typeof this.value === 'string' && this.value.toString().length > this.realLengthText
  }

  get displayContent() {
    if (this.value && typeof this.value === 'string' && this.value.toString().length > this.realLengthText) {
      return `${this.value.substr(0, this.realLengthText)}...`
    } else {
      return this.value
    }
  }

  copyClipboard(value: string) {
    copyClipboard(value, this.$t('copy_success'))
  }

  displayLocation(value: string) {
    window.open(`https://www.google.com/maps/search/${encodeURIComponent(value)}`, '_blank')
  }
}
</script>

<style lang="scss" scoped>
.icon-copy-container {
  top: 50%;
  transform: translate(0, -50%);
}

.tooltip-button:deep() {
  button {
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif, Helvetica, Arial;
    color: #000;
    font-weight: 400;
    &:hover {
      color: #000;
      background-color: #fff;
    }
  }
}
</style>
