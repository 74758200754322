
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import { IFileItem } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ name: 'BarcodeRowImage' })
export default class BarcodeRowImage extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly values?: Array<IFileItem>
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean
}
