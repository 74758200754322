<template>
  <div class="flex mx--2">
    <div class="px-2px" :class="`w-${labelWidth}`">
      <div class="h-full text-gray-500 bg-gray-100 px-2">
        <template v-if="isLabelTooltip">
          <div class="tooltip-label flex items-center h-full">
            <el-tooltip
              :class="`box-item w-full ${isMaxWordLabelLength ? 'break-all' : ''}`"
              effect="dark"
              :content="label"
              placement="top"
            >
              <label>{{ displayLabel }}</label>
            </el-tooltip>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center h-full">
            <span class="mr-2">{{ label }}</span>
            <template v-if="hidden">
              <el-tooltip class="font-bold" effect="dark" :content="$t('hidden_field_tooltip')" placement="top">
                <span class="bold text-blue-600">ⓘ</span>
              </el-tooltip>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div :class="`w-full--${labelWidth} px-2px relative`">
      <div class="bg-white px-2 custom-field-carousel">
        <el-carousel
          :arrow="(values ?? []).length > 1 ? 'always' : 'never'"
          :autoplay="false"
          :key="(values ?? []).length"
          height="260px"
        >
          <el-carousel-item v-for="(image, index) in values" :key="index">
            <el-image :src="image?.url" fit="contain">
              <template #error>
                <div style="height: 20px">{{ $t('image_not_found') }}</div>
              </template>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import { IFileItem } from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ name: 'BarcodeRowImage' })
export default class BarcodeRowImage extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly values?: Array<IFileItem>
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean
}
</script>

<style lang="scss" scoped>
.img-sign {
  height: 160px;
}
</style>
