<template>
  <div v-loading.fullscreen="loading">
    <!-- lend dialog-children-barcodes class => should change this later -->
    <el-dialog
      width="70%"
      top="0"
      custom-class="dialog-children-barcodes"
      :model-value="_isShowDialog"
      :close-on-click-modal="true"
      :show-close="true"
      @closed="_isShowDialog = false"
    >
      <template #title>
        <div class="flex justify-between mr-2">
          <span class="self-center"> {{ $t('barcode.barcode_history_list') }}</span>
          <el-button
            @click="onCSVDownload"
            type="default"
            class="bg-green-min text-white focus:text-white rounded-md mr-8"
            size="mini"
          >
            <div class="flex flex-col items-center">
              <span class="ml-1">{{ $t('barcodeImport.csvDownload') }}</span>
              <IconDownload />
            </div>
          </el-button>
        </div>
      </template>
      <div class="flex flex-col h-full">
        <div v-if="displayItems?.length > 0" class="flex-grow flex flex-col">
          <div class="my-8 flex-grow">
            <el-table
              :data="displayItems"
              class="rounded-xl w-full"
              header-cell-class-name="custom-table__header-cell"
              cell-class-name="custom-table__cell cursor-pointer"
              border
              :empty-text="$t('empty')"
              highlight-current-row
              @sort-change="sortBarcode"
              @current-change="onEditBarcode"
            >
              <el-table-column prop="id" :label="$t('barcode.id')" :min-width="20" sortable />
              <el-table-column :label="$t('projects.barcodeType')" :min-width="20">
                <template v-slot="scope">
                  <div class="flex" v-if="getBarcodeType(scope.row.barcodeType)">
                    <div class="w-8 h-8 barcode-icon-container flex item-centers">
                      <BarcodeIcon :barcodeType="getBarcodeType(scope.row.barcodeType)" :isShowLabel="false" />
                    </div>
                    <div class="flex-grow flex items-center ml-4">
                      {{ getBarcodeType(scope.row.barcodeType)?.name }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="projectVersion" :label="$t('barcode.projectVersion')" :min-width="20" sortable />
              <el-table-column :label="$t('barcode.currentTrackpoint')" :min-width="20">
                <template v-slot="scope">{{ getTrackPointName(scope.row.currentTrackPointKey) }}</template>
              </el-table-column>
              <el-table-column prop="audit.createdDateTime" :label="$t('barcode.createdDate')" :min-width="20" sortable>
                <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit?.createdDateTime) }}</template>
              </el-table-column>
              <el-table-column prop="recycledDateTime" :label="$t('barcode.recycled_date')" :min-width="20" sortable>
                <template v-slot="scope">{{ displayDateTimeLocal(scope.row.audit.recycledDateTime) }}</template>
              </el-table-column>
              <el-table-column prop="isActive" :label="$t('status')" :min-width="10">
                <template v-slot="scope">{{ scope.row.isActive ? $t('active') : $t('inactive') }}</template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <template v-else>
          <ItemNotFound class="mt-56" :content="$t('empty')" />
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import { Options } from 'vue-class-component'
import { Prop, PropSync, Watch, Mixins, Emit } from 'vue-property-decorator'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import { TABLE_SORT_TYPE } from '@/utils/constants'
import { IBarcode, IBarcodeList, IBarcodeDefinitionType, ICommonSearch, ITableSortData, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: { ItemNotFound, BarcodeIcon, IconDownload },
  emits: ['onHistoryDownload'],
  name: 'BarcodeHistoryDialog',
})
export default class BarcodeHistoryDialog extends Mixins(DataTableMixin) {
  @Prop({ type: Array }) readonly barcodes!: IBarcode[]
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  dataSearch = {} as ICommonSearch

  loading = false

  @Watch('barcodes')
  onBarcodesChanged() {
    this.displayItems = this.barcodes
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get projectDetails() {
    return this.$store.state.barcode?.projectDetail
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.projectDetails?.trackPoints
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.projectDetails?.barcodeTypes?.[barcodeTypeCode]
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  onEditBarcode(barcode: IBarcodeList) {
    this.$router.push({ name: 'barcodeView', params: { id: barcode.id } })
    this._isShowDialog = false
  }

  @Emit('onHistoryDownload')
  onCSVDownload() {
    return 'rootId'
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order]
    this.redirectIndex()
    this.sortDisplayItems()
  }
}
</script>
