
import { Options } from 'vue-class-component'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { Mixins } from 'vue-property-decorator'
import { TABLE_SORT_TYPE } from '@/utils/constants'
import { IBarcodeList, IBarcodeSearchForm, IBarcodeDefinitionType, ITableSortData, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: { ItemNotFound, BarcodeIcon },
  emits: ['dialog:close'],
  name: 'BarcodeChildren',
})
export default class BarcodeChildren extends Mixins(DataTableMixin) {
  dataSearch = {} as IBarcodeSearchForm

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.barcode?.projectDetail?.trackPoints
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.$store.state.barcode?.projectDetail?.barcodeTypes?.[barcodeTypeCode]
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  onEditBarcode(barcode: IBarcodeList) {
    const id = barcode.id
    this.$router.push({
      name: 'barcodeView',
      params: { id },
    })

    this.$emit('dialog:close')
  }

  created() {
    this.displayItems = this.$store.state.barcode?.barcodeChildren
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order]
    this.redirectIndex()
    this.sortDisplayItems()
  }
}
