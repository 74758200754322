
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import IconAddressLocation from '@/components/svg/IconAddressLocation.vue'
import IconCopy from '@/components/svg/IconCopy.vue'
import { copyClipboard } from '@/utils/helpers'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ components: { IconCopy, IconAddressLocation }, name: 'BarcodeRow' })
export default class BarcodeRow extends Mixins(BarcodeRowMixin) {
  @Prop({ type: String }) readonly value?: string | number
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean
  @Prop({ type: Boolean, default: false }) readonly isCoppy!: boolean
  @Prop({ type: Boolean, default: false }) readonly isAddressLocation!: boolean

  get realLengthText() {
    return this.maxLengthFollowRealValue(this.value ?? '', this.maxLength ?? 40)
  }

  get isTooltip() {
    return this.value && typeof this.value === 'string' && this.value.toString().length > this.realLengthText
  }

  get displayContent() {
    if (this.value && typeof this.value === 'string' && this.value.toString().length > this.realLengthText) {
      return `${this.value.substr(0, this.realLengthText)}...`
    } else {
      return this.value
    }
  }

  copyClipboard(value: string) {
    copyClipboard(value, this.$t('copy_success'))
  }

  displayLocation(value: string) {
    window.open(`https://www.google.com/maps/search/${encodeURIComponent(value)}`, '_blank')
  }
}
