
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import { Options } from 'vue-class-component'
import { Prop, PropSync, Watch, Mixins, Emit } from 'vue-property-decorator'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import { TABLE_SORT_TYPE } from '@/utils/constants'
import { IBarcode, IBarcodeList, IBarcodeDefinitionType, ICommonSearch, ITableSortData, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  components: { ItemNotFound, BarcodeIcon, IconDownload },
  emits: ['onHistoryDownload'],
  name: 'BarcodeHistoryDialog',
})
export default class BarcodeHistoryDialog extends Mixins(DataTableMixin) {
  @Prop({ type: Array }) readonly barcodes!: IBarcode[]
  @PropSync('isShowDialog', { type: Boolean }) _isShowDialog!: boolean
  dataSearch = {} as ICommonSearch

  loading = false

  @Watch('barcodes')
  onBarcodesChanged() {
    this.displayItems = this.barcodes
  }

  displayDateTimeLocal(time: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, time)
  }

  get projectDetails() {
    return this.$store.state.barcode?.projectDetail
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.projectDetails?.trackPoints
  }

  getBarcodeType(barcodeTypeCode: string): IBarcodeDefinitionType {
    return this.projectDetails?.barcodeTypes?.[barcodeTypeCode]
  }

  getTrackPointName(id: string) {
    return this.trackpoints?.[id]?.name
  }

  onEditBarcode(barcode: IBarcodeList) {
    this.$router.push({ name: 'barcodeView', params: { id: barcode.id } })
    this._isShowDialog = false
  }

  @Emit('onHistoryDownload')
  onCSVDownload() {
    return 'rootId'
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order]
    this.redirectIndex()
    this.sortDisplayItems()
  }
}
