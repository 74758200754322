<template>
  <svg iconName="IconCommonFile" :height="36" :width="32" fill="none">
    <g clip-path="url(#clip0_8728_7365)">
      <path
        d="M1.55176 24.436V4.28211C1.55176 3.55772 1.83952 2.86299 2.35175 2.35077C2.86397 1.83855 3.55869 1.55078 4.28309 1.55078H27.7063C28.4316 1.55078 29.1274 1.83821 29.6413 2.35014C30.1552 2.86207 30.4452 3.55677 30.448 4.28211V31.5644C30.4452 32.2897 30.1552 32.9844 29.6413 33.4963C29.1274 34.0083 28.4316 34.2957 27.7063 34.2957H11.3804C10.9144 34.2945 10.4679 34.1085 10.1389 33.7784L2.06905 25.6775C1.73895 25.3486 1.5529 24.902 1.55176 24.436V24.436Z"
        stroke="#333333"
        stroke-width="3.3"
        stroke-miterlimit="10"
      />
      <path
        d="M1.47949 24.5703H10.0873C10.402 24.573 10.7031 24.6992 10.9256 24.9218C11.1482 25.1443 11.2744 25.4454 11.2771 25.7601V34.3369"
        stroke="#333333"
        stroke-width="3.3"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_8728_7365">
        <rect width="32" height="35.8487" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconCommonFile extends Vue {}
</script>
