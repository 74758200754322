<template>
  <SvgBase iconName="IconUpload" :height="17" :width="22"
    ><path
      d="M7 11.0625L8.269 12.2081L10.6 10.1119V17H12.4V10.1119L14.731 12.2081L16 11.0625L11.5 7L7 11.0625Z"
      :fill="color"
    />
    <path
      d="M16.9102 15H16.5162V13.498H16.9102C17.8507 13.5338 18.7676 13.2122 19.4593 12.6037C20.1509 11.9953 20.5606 11.1499 20.5982 10.2536C20.6358 9.35733 20.2983 8.4835 19.6599 7.82436C19.0214 7.16522 18.1344 6.77478 17.1939 6.73893H16.5162L16.4374 6.12311C16.2626 4.85875 15.6115 3.69806 14.6053 2.85727C13.5992 2.01647 12.3066 1.5529 10.9685 1.5529C9.63032 1.5529 8.33778 2.01647 7.33164 2.85727C6.32551 3.69806 5.67438 4.85875 5.49954 6.12311L5.48378 6.73893H4.80608C3.86558 6.77478 2.97855 7.16522 2.34012 7.82436C1.70169 8.4835 1.36416 9.35733 1.40178 10.2536C1.4394 11.1499 1.84909 11.9953 2.54072 12.6037C3.23235 13.2122 4.14927 13.5338 5.08977 13.498H5.48378V15H5.08977C3.82609 14.9924 2.60997 14.5398 1.67522 13.7294C0.740471 12.9189 0.153083 11.8078 0.0259841 10.6095C-0.101115 9.4113 0.241049 8.21059 0.98669 7.23824C1.73233 6.2659 2.82881 5.59056 4.06533 5.34206C4.40553 3.82992 5.27955 2.4747 6.54157 1.50252C7.80358 0.530336 9.37725 0 11 0C12.6227 0 14.1964 0.530336 15.4584 1.50252C16.7204 2.4747 17.5945 3.82992 17.9347 5.34206C19.1712 5.59056 20.2677 6.2659 21.0133 7.23824C21.759 8.21059 22.1011 9.4113 21.974 10.6095C21.8469 11.8078 21.2595 12.9189 20.3248 13.7294C19.39 14.5398 18.1739 14.9924 16.9102 15V15Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUpload extends Vue {}
</script>
