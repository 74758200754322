
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'BarcodeRowMixin',
})
export default class BarcodeRowMixin extends Vue {
  @Prop({ type: Number }) readonly labelWidth!: number
  @Prop({ type: String }) readonly label?: string
  @Prop({ type: Number, default: 30 }) readonly maxLength?: number
  @Prop({ type: Number, default: 60 }) readonly maxLabelLength?: number

  get maxWordLabelLength() {
    return (this.maxLabelLength ?? 30) / 2
  }

  isMaxWordLength(text: string) {
    const words = text.split(' ')
    let isLongWord = false
    words.every((word) => {
      if (word.length > this.maxWordLabelLength) {
        isLongWord = true
        return false
      }
      return true
    })
    return isLongWord
  }

  maxLengthFollowRealValue(text: string, length: number) {
    let tempLength = 0
    let charIndex = 0
    for (charIndex = 0; charIndex < text.length; charIndex++) {
      tempLength += text.charCodeAt(charIndex) < 256 ? 1 : 1.5
      if (tempLength > length) {
        return charIndex
      }
    }
    return charIndex
  }

  get realLengthLabel() {
    return this.maxLengthFollowRealValue(
      this.label ?? '',
      this.isMaxWordLength(this.label ?? '') ? this.maxWordLabelLength : this.maxLabelLength ?? 30
    )
  }

  get isLabelTooltip() {
    return (this.label || '').length > this.realLengthLabel
  }

  get displayLabel() {
    return `${(this.label || '').substr(0, this.realLengthLabel)}...`
  }
}
