<template>
  <SvgBase iconName="miniCode" width="30" height="29" fill="none">
    <rect x="9.65" y="5.65" width="12.7" height="12.7" rx="2.35" stroke="#54ACFC" stroke-width="1.3" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.12854 9.12891C7.04495 9.4047 7 9.69728 7 10.0004V10.6255C6.57573 10.9346 6.3 11.4353 6.3 12.0004V20.0004C6.3 20.9393 7.06112 21.7004 8 21.7004H16C16.5651 21.7004 17.0658 21.4246 17.3749 21.0004H18C18.3031 21.0004 18.5957 20.9554 18.8715 20.8718C18.4981 22.1037 17.3538 23.0004 16 23.0004H8C6.34315 23.0004 5 21.6572 5 20.0004V12.0004C5 10.6466 5.89669 9.50227 7.12854 9.12891Z"
      fill="#54ACFC"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconCopy extends Vue {}
</script>
