<template>
  <div class="flex mx--2">
    <div class="px-2px" :class="`w-${labelWidth}`">
      <div class="text-gray-500 bg-gray-100 flex items-center px-2 h-full break-all">
        {{ $t('barcode_type.eSign') }}
      </div>
    </div>
    <div :class="`w-full--${labelWidth} px-2px relative`">
      <div class="bg-white px-2 custom-esign-carousel">
        <el-carousel
          :arrow="(customFieldImages ?? []).length > 1 ? 'always' : 'never'"
          :autoplay="false"
          :key="(customFieldImages ?? []).length"
          :height="'50px'"
          @change="changeSlide"
        >
          <el-carousel-item v-for="(image, index) in customFieldImages" :key="index">
            <div class="w-full h-full flex items-center justify-center px-4">{{ image.label }}</div>
          </el-carousel-item>
        </el-carousel>
        <el-carousel
          arrow="never"
          :autoplay="false"
          :key="(customFieldImages ?? []).length"
          ref="imageCarousel"
          height="210px"
        >
          <el-carousel-item v-for="(image, index) in customFieldImages" :key="index">
            <el-image :src="image?.value" fit="contain">
              <template #error>
                <div style="height: 20px">{{ $t('image_not_found') }}</div>
              </template>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BarcodeRowMixin from '@/components/mixins/BarcodeRowMixin.vue'
import { Options } from 'vue-class-component'
import { Mixins, Prop } from 'vue-property-decorator'

@Options({ name: 'BarcodeRowEsign' })
export default class BarcodeRowEsign extends Mixins(BarcodeRowMixin) {
  @Prop({ type: Array }) readonly customFieldImages?: Array<Record<string, string>>
  @Prop({ type: Boolean, default: false }) readonly hidden!: boolean

  changeSlide(index: number) {
    this.$refs.imageCarousel.setActiveItem(index)
  }
}
</script>

<style lang="scss" scoped>
.img-sign {
  height: 160px;
}
</style>
