<template>
  <SvgBase iconName="miniCode" width="29" height="28" fill="none">
    <path
      d="M21.2726 11.5455C21.2726 16.6364 14.7271 21 14.7271 21C14.7271 21 8.18164 16.6364 8.18164 11.5455C8.18164 9.80949 8.87125 8.14463 10.0988 6.91712C11.3263 5.68961 12.9911 5 14.7271 5C16.4631 5 18.1279 5.68961 19.3554 6.91712C20.5829 8.14463 21.2726 9.80949 21.2726 11.5455Z"
      stroke="#54ACFC"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.7267 13.7274C15.9317 13.7274 16.9086 12.7506 16.9086 11.5456C16.9086 10.3406 15.9317 9.36377 14.7267 9.36377C13.5218 9.36377 12.5449 10.3406 12.5449 11.5456C12.5449 12.7506 13.5218 13.7274 14.7267 13.7274Z"
      stroke="#54ACFC"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M21.5 17.5L23.5 22H18" stroke="#54ACFC" stroke-width="1.3" />
    <path d="M8 17.5L6 22H11.5" stroke="#54ACFC" stroke-width="1.3" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconAddressLocation extends Vue {}
</script>
